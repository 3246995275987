import { ref } from "vue";
import ApiService from "@/common/service.api";
export function useSurveys() {
  const loading = ref({
    getSurveyByCompany: false,
  });

  const surveyByCompany = ref([]);
  const mySurveys = ref([]);

  const getSurveyByCompany = async (idCompany) => {
    loading.value.getSurveyByCompany = true;
    surveyByCompany.value.splice(0);
    try {
      surveyByCompany.value = await ApiService.get(
        "Survey/GetByCompany/" + idCompany
      ).data;
    } catch (err) {
      console.log("WHAPPY ERROR : composable useSurveys -> ", err);
    } finally {
      loading.value.getSurveyByCompany = false;
    }
  };

  const getMySurveys = async (idUser) => {
    loading.value.getMySurveys = true;
    mySurveys.value.splice(0);

    try {
      mySurveys.value = await ApiService.get("Survey/GetMySurveys/" + idUser);
    } catch (err) {
      console.log("WHAPPY ERROR : composable useSurveys -> ", err);
    } finally {
      loading.value.getMySurveys = false;
    }
  };

  return {
    //data
    loading,
    surveyByCompany,
    mySurveys,
    //methods
    getSurveyByCompany,
    getMySurveys,
  };
}
