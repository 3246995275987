<template>
  <ion-card class="py-2">
    <div class="page-header">
      <div class="title-header">
        <span class="mb-2">{{ titlePrefix }}</span>
        <span class="font-bold">{{ title }}</span>
      </div>
      <div class="title-details">
        <div
          class="w-full flex justify-content-end align-items-center text-14 mb-2"
          style="color: #656979; text-align: end"
        >
          <IonIcon class="mr-2" :icon="calendarClear" />
          <span>{{ surveyDurationText }}</span>
        </div>
        <span class="text-14">{{
          new Date(surveyDuration).toLocaleDateString("it", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          })
        }}</span>
      </div>
    </div>

    <div class="separator"></div>

    <div class="page-container">
      <div class="w-full flex justify-content-end align-items-center">
        <small style="width: 4.5rem">Vedi lista da valutare</small>
        <ion-icon
          @click="toggleUsers"
          :icon="isCollapsed ? chevronDown : chevronUp"
          class="text-26"
        ></ion-icon>
      </div>

      <div v-if="!isCollapsed" class="p-3">
        <UserCard
          v-for="user in users"
          :key="user.name"
          :user="user"
          v-model="user.selected"
          :disabled="true"
          @click="goToSurvey(user)"
        />
      </div>
    </div>
  </ion-card>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { IonIcon, IonCard } from "@ionic/vue";
import UserCard from "./UserCard.vue";
import { calendarClear, chevronUp, chevronDown } from "ionicons/icons"; // Assicurati di importare l'icona corretta

import { useRouter } from "vue-router";
import { useGeneralStore } from "@/stores/useGeneralStore";
import { usePkSurvey } from "@/stores/usePkSurvey";

const generalStore = useGeneralStore();
const pkSurvey = usePkSurvey();
const router = useRouter();

// Props
const props = defineProps({
  titlePrefix: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  surveyDurationText: {
    type: String,
    default: "Survey duration",
  },
  surveyDuration: {
    type: String,
    default: "",
  },
  users: {
    type: Array,
    required: true,
  },
});

// State
const isCollapsed = ref(true);

// Methods
const toggleUsers = () => {
  isCollapsed.value = !isCollapsed.value;
};

const goToSurvey = (user) => {
  // Logica per andare alla survey di un utente
  console.log("Going to survey for", user);
  pkSurvey.setCurrentUser(user);
  generalStore.setCurrentSurvey({ ...user });
  router.push("/pk-survey/124");
};
</script>

<style scoped>
.title-header {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.title-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: #525252;
}

.page-container {
  padding: 16px 16px 0;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.card {
  border-radius: 10px;
  background-color: var(--ion-color-primary);
  padding: 20px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  min-height: 100px;
}

.toggle-users-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0.5rem;
}
</style>
